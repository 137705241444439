import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const EditMetaTag = ({ data }) => {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleSubmit = async (e) => {
    try {
      await axios
        .put(`/meta/${data.id}`, {
          title,
          description,
          selected_value: data?.selected_value,
        })
        .then((data) => setIsUpdated(true))
        .catch((err) => console.log(err));
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="add">
      <div className="content">
        <input
          type="text"
          placeholder="title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />
        <textarea
          rows={4}
          type="text"
          placeholder="description"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          required
        />
        <button onClick={handleSubmit}>Publish</button>
      </div>
    </div>
  );
};

const Meta = () => {
  const [blogs, setBlogs] = useState([]);
  const [editPostId, setEditPostId] = useState(null);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/meta");
        setBlogs(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [isUpdated]);

  const handleSubmit = async (data) => {
    try {
      await axios
        .put(`/meta/${data.id}`, {
          title,
          description,
          selected_value: data?.selected_value,
        })
        .then((data) => {
          setIsUpdated(true);
          setEditPostId(null);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log("err", err);
    }
  };
  
  return (
    <div className="home">
      <div className="posts">
        {blogs?.map((post) => {
          return (
            <div
              style={{ border: "1px solid #555", padding: "2rem" }}
              className="post"
              key={post.id}
            >
              <div className="content">
                <h2
                  style={{
                    textTransform: "uppercase",
                    borderBottom: "1px solid",
                  }}
                >
                  {post.selected_value} page
                </h2>

                <h1>{post.title}</h1>
                <p>{post.description}</p>
                <div className="edit">
                  {editPostId === post.id ? (
                    <i
                      className="meta-icon fas fa-regular fa-times"
                      onClick={() => setEditPostId(null)}
                    ></i>
                  ) : (
                    <i
                      onClick={() => {
                        setIsUpdated(false);
                        setEditPostId(post.id);
                        setTitle(post.title);
                        setDescription(post.description);
                      }}
                      className="meta-icon fas fa-regular fa-pen-to-square"
                    ></i>
                  )}
                  {editPostId === post.id && (
                    <>
                      <div className="add">
                        <div className="content">
                          <input
                            type="text"
                            placeholder="title"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            required
                          />
                          <textarea
                            rows={4}
                            type="text"
                            placeholder="description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            required
                          />
                          <button onClick={() => handleSubmit(post)}>
                            Publish
                          </button>
                        </div>
                      </div>
                      {/* <EditMetaTag data={post} /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Meta;
