import React, { useContext, useEffect, useState } from "react";
import img from "../../src/bg11.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import axios from "axios";
import moment from "moment/moment.js";
import { AuthContext } from "../context/authContext";

const Single = () => {
  const [blog, setBlog] = useState([]);
  const location = useLocation();
  const blogId = location.pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/blogs/${blogId}`);
        setBlog(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [blogId]);

  const base64Data = btoa(
    new Uint8Array(blog?.img?.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );

  const handleDelete = async () => {
    try {
      const res = await axios.delete(`/blogs/${blogId}`);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };
  return (
    <div className="single">
      <div className="content">
        <img src={`data:image/jpeg;base64,${base64Data}`} alt="..." />
        <div className="user">
          {blog.userImg && <img src={img} alt="..." />}
          <div className="info">
            <span>{blog?.username}</span>

            <p>Posted {moment(blog?.date).fromNow()}</p>
          </div>
          {currentUser.username === blog.username && (
            <div className="edit">
              <Link to={"/write"} state={blog}>
                <p>E</p>
              </Link>
              <p onClick={handleDelete}>D</p>
            </div>
          )}
        </div>
        <h1>{blog?.title}</h1>
        {getText(blog?.description)}
      </div>
      {/* <Menu /> */}
    </div>
  );
};

export default Single;
