import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <Link className="link" to={"/"}>
            <h2>ADMIN PANEL</h2>
          </Link>
        </div>
        <div className="links">
          <Link className="link" to="/">
            <h6>HOME</h6>
          </Link>
          <Link className="link" to="/meta">
            <h6>META</h6>
          </Link>
          <span className="write">
            <Link className="link" to="/write">
              Blog
            </Link>
          </span>
          <>
            <span
              style={{
                border: "1px solid #555",
                padding: ".3rem",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              {currentUser?.username}
            </span>
            {currentUser ? (
              <span
                onClick={() => {
                  navigate("/login");
                  logout();
                }}
              >
                <i className="fas fa-regular fa-right-from-bracket"></i>
              </span>
            ) : (
              <Link className="link" to={"/login"}>
                Login
              </Link>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
