import React, { useEffect, useState } from "react";
import img from "../../src/bg11.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/blogs");
        setBlogs(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <div className="home">
      <div className="posts">
        {blogs?.map((post) => {
          const base64Data = btoa(
            new Uint8Array(post.img.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const bufImg = `data:image/jpeg;base64,${base64Data}`;

          const words = post?.description?.split(" ");
          const shortenedWords = words?.slice(0, 50);
          const shortenedString = shortenedWords?.join(" ");

          return (
            <div className="post" key={post.id}>
              <div className="img">
                <img src={bufImg} alt="..." />
              </div>
              <div className="content">
                <Link className="link" to={`/post/${post.id}`}>
                  <h1>{post.title}</h1>
                </Link>
                {getText(shortenedString)}
                <button>Read More</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
