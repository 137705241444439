import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";

const Write = () => {
  const state = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(state?.description || "");
  const [title, setTitle] = useState(state?.title || "");
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", value);
    formData.append("image", image);

    try {
      state
        ? await axios
            .put(`/blogs/${state.id}`, formData)
            .then((data) => navigate("/"))
            .catch((err) => console.log(err))
        : await axios
            .post(`/blogs`, formData)
            .then((data) => navigate("/"))
            .catch((err) => console.log(err));
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <div className="add">
      <div className="content">
        <input
          type="text"
          placeholder="title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />
        <div className="editorContainer">
          <ReactQuill
            className="editor"
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
      </div>
      <div className="menu">
        <div className="item">
          <h1>Publish</h1>
          {/* <span>
            <b>Status: </b> Draft
          </span>
          <span>
            <b>Visibilty: </b> Public
          </span> */}
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input
              style={{
                // display: "none",
                cursor: "pointer",
              }}
              type="file"
              name=""
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
            <label className="file" htmlFor="file">
              Upload Image
            </label>
            <div className="buttons">
              <button>Save as a draft</button>
              <button type="submit">Publish</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Write;
